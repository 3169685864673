<script setup lang="ts">
import NotificationList from '../NotificationList/NotificationList.vue';
import { useHeaderSubNavForMember } from '../useHeaderNavs';
import type { FragmentType } from '@/gql';
import { graphql } from '@/gql';
import ButtonNotification from 'theme/components/atoms/ButtonNotification.vue';
import UserThumbnail from 'theme/components/atoms/UserThumbnail.vue';
import FloatingPanel from 'theme/components/molecules/FloatingPanel.vue';
import FadeInTransition from 'theme/components/transitions/FadeInTransition.vue';
import IconExternalLink from '~/assets/icons/outline/external-link.svg?component';

const props = defineProps<{
  viewer: FragmentType<typeof AppHeaderContentsForPcMemberNavViewerFragment>;
}>();

const isNotificationPanelOpened = ref(false);
const isNavigationPanelOpened = ref(false);

const { notifications } = useNotifications();
const notificationCount = computed(() => notifications.value.length);

const AppHeaderContentsForPcMemberNavViewerFragment = graphql(/* GraphQL */ `
  fragment AppHeaderContentsForPCMemberNavViewer on Viewer {
    ...UseHeaderNavsViewerForMember
    nickname
    latestDiagnosis {
      result {
        personality {
          type
        }
      }
    }
  }
`);

const viewerFragment = useComputedFragment(
  AppHeaderContentsForPcMemberNavViewerFragment,
  () => props.viewer
);

const nickname = useUserNicknameForDisplay(viewerFragment.value.nickname);
const personalityId = computed(() => {
  return viewerFragment.value.latestDiagnosis?.result.personality.type;
});
const subNavs = useHeaderSubNavForMember(viewerFragment);

const openNotificationPanel = () => {
  isNotificationPanelOpened.value = true;
};
const closeNotificationPanel = () => {
  isNotificationPanelOpened.value = false;
};
const openNavigationPanel = () => {
  isNavigationPanelOpened.value = true;
  isNotificationPanelOpened.value = false;
};
const closeNavigationPanel = () => {
  isNavigationPanelOpened.value = false;
};

const { logout } = useLogout();
</script>

<template>
  <nav>
    <ul :class="$style.actions">
      <li :class="[$style.actions__notification, $style.notification]">
        <ButtonNotification
          :class="$style.notification__button"
          :notification-count="notificationCount"
          @click="openNotificationPanel"
        />
        <FadeInTransition :duration="100">
          <FloatingPanel
            v-if="isNotificationPanelOpened"
            :class="$style['notification__panel']"
            @close:floating-panel="closeNotificationPanel"
          >
            <NotificationList :notifications="notifications" />
          </FloatingPanel>
        </FadeInTransition>
      </li>
      <li
        v-test="'logged-in-user-nav-menu'"
        :class="[$style.actions__user, $style['user-nav']]"
        @click="openNavigationPanel"
        @mouseover="openNavigationPanel"
        @mouseleave="closeNavigationPanel"
      >
        <div :class="[$style['user-nav__profile'], $style.profile]">
          <UserThumbnail
            :personality-type="personalityId"
            :class="$style.profile__thumbnail"
            :alt="nickname"
          />
          <div :class="$style.profile__box">
            <p :class="$style.profile__name">{{ nickname }}</p>
          </div>
        </div>
        <FadeInTransition :duration="100" :leaving-delay="100">
          <FloatingPanel
            v-if="isNavigationPanelOpened"
            :class="$style['user-nav__panel']"
            toggle-trigger="hover"
          >
            <ul :class="$style['panel-links']">
              <li
                v-for="(nav, index) in subNavs"
                :key="index"
                :class="$style['panel-links__item']"
              >
                <section
                  v-if="nav.condition && nav.isExternalLink"
                  :class="[$style.link, $style.externalLink]"
                >
                  <NuxtLink
                    :class="[$style.link, $style.externalLink__text]"
                    :href="nav.href"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ nav.title }}
                  </NuxtLink>
                  <IconExternalLink :class="$style.externalLink__icon" />
                </section>
                <NuxtLink
                  v-else-if="nav.condition"
                  :href="nav.href"
                  :class="$style.link"
                >
                  {{ nav.title }}
                </NuxtLink>
              </li>
              <li :class="$style['panel-links__item']">
                <button :class="$style.link" @click="logout">ログアウト</button>
              </li>
            </ul>
          </FloatingPanel>
        </FadeInTransition>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" module>
.actions {
  $root: &;
  display: flex;
  align-items: stretch;
  height: 100%;
  list-style: none;

  &__notification {
    width: 72px;
    flex: 0 0 auto;
  }

  &__user {
    flex: 0 0 auto;
  }
}

.notification {
  position: relative;

  &__button {
    padding: 24px;
    height: 100%;
  }

  &__panel {
    position: absolute;
    top: calc(100% - 16px);
    right: 24px;
    width: 400px;
  }
}

.user-nav {
  $root: &;
  position: relative;

  &__profile {
    @include is-pointer-device {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__panel {
    position: absolute;
    top: calc(100% - 16px);
    right: 0;
    width: 212px;
  }
}

.profile {
  display: flex;
  align-items: center;
  height: 100%;

  &__thumbnail {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
  }

  &__box {
    margin-left: 16px;
  }

  &__name {
    flex-shrink: 0;
    display: block;
    font-size: 16px;
    font-weight: bold;
  }
}

.point {
  &__unit {
    font-size: 12px;
  }
}

.panel-links {
  list-style: none;
  padding: 32px;

  &__item {
    line-height: 1;

    &:nth-child(n + 2) {
      margin-top: 24px;
    }
  }
}

.link {
  color: $color-text-and-object-medium-emphasis;

  @include is-pointer-device {
    &:hover {
      color: $color-text-and-object-high-emphasis;
    }
  }
}

.externalLink {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 4px;

  &__icon {
    width: 14px;
    height: 14px;
  }
}
</style>
