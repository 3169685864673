<script setup lang="ts">
import AppHeaderContainer from './AppHeaderContainer.vue';
import AppHeaderContentsForPc from './AppHeaderContentsForPc/AppHeaderContentsForPc.vue';
import AppHeaderContentsForSp from './AppHeaderContentsForSp/AppHeaderContentsForSp.vue';
import type { FragmentType } from '@/gql';
import { graphql } from '@/gql';
import { useDeviceType } from 'theme/composables/useDeviceType';

const props = withDefaults(
  defineProps<{
    viewer?: FragmentType<typeof AppHeaderViewerFragment>;
    exclusive?: boolean;
    noDivider?: boolean;
  }>(),
  {
    viewer: undefined,
    exclusive: false,
    noDivider: false,
  }
);

const emit = defineEmits<{
  openNotification: [];
  openDrawer: [];
}>();

const AppHeaderViewerFragment = graphql(/* GraphQL */ `
  fragment AppHeaderViewer on Viewer {
    ...AppHeaderContentsForPcViewer
    ...AppHeaderContainerViewer
  }
`);

const viewerFragment = useComputedFragment(
  AppHeaderViewerFragment,
  () => props.viewer
);
const { isDesktop } = useDeviceType();
</script>

<template>
  <AppHeaderContainer
    v-test="'application-header'"
    :exclusive="exclusive"
    :no-divider="noDivider"
    :viewer="viewerFragment ?? undefined"
  >
    <template #logo>
      <slot name="logo" />
    </template>
    <ClientOnly v-if="!exclusive">
      <AppHeaderContentsForPc
        v-if="isDesktop"
        :viewer="viewerFragment ?? undefined"
      />
      <AppHeaderContentsForSp
        v-else
        @open-notification="emit('openNotification')"
        @open-drawer="emit('openDrawer')"
      />
    </ClientOnly>
  </AppHeaderContainer>
</template>
