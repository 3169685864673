<script setup lang="ts">
import IconBell from './icons/IconBell.vue';
import NotificationBadgeWithCount from '~theme/components/molecules/NotificationBadgeWithCount.vue';

withDefaults(
  defineProps<{
    notificationCount: number;
  }>(),
  { notificationCount: 0 }
);

const emit = defineEmits<{
  click: [MouseEvent];
}>();
</script>

<template>
  <button
    :class="$style['button']"
    aria-label="通知"
    @click="emit('click', $event)"
  >
    <span :class="$style['container']">
      <IconBell :class="$style['container__icon']" />
      <NotificationBadgeWithCount
        v-show="!!notificationCount"
        :count="notificationCount"
        :class="$style['container__badge']"
      />
    </span>
  </button>
</template>

<style lang="scss" module>
.button {
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;

  &__icon {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    color: $color-text-and-object-medium-emphasis;
    transition: color $transition-interaction;

    &:hover {
      color: $color-text-and-object-high-emphasis;
    }

    &:active {
      color: $color-text-and-object-medium-emphasis;
    }
  }

  &__badge {
    position: absolute;
    top: -30%;
    left: 50%;
    font-size: 10px;
  }
}
</style>
