import { Channel } from '~/gql/graphql';

export const useLpLocation = (channel: MaybeRefOrGetter<Nullish<Channel>>) => {
  const lpLocation = computed(() => {
    switch (toValue(channel)) {
      case Channel.Famipay:
        return { path: '/partner/famipay' };
      case Channel.FourHundredFInsuranceComparison:
        return { path: '/insurance' };
      case Channel.Hanasaku:
        return { path: '/partner/hanasaku' };
      case Channel.HokenNoMadoguchi:
        return { path: '/partner/hokennomadoguchi_tsuhan' };
      case Channel.Kiraboshi:
        return { path: '/partner/kiraboshi' };
      case Channel.MoneyForward:
        return { path: '/partner/moneyforward' };
      case Channel.Okb:
        return { path: '/partner/okb' };
      case Channel.Ponta:
        return { path: '/partner/ponta' };
      case Channel.Rakuten:
        return { path: '/partner/rakuten' };
      case Channel.RakutenInHouseAdvisor:
        return { path: '/partner/rakuten_inhouseadvisor' };
      case Channel.Sbi:
        return { path: '/partner/sbi' };
      case Channel.Smcc:
        return { path: '/partner/smcc' };
      case Channel.ZaikeiJutakuKinyu:
        return { path: '/partner/zjk' };
      case Channel.FourHundredF:
      case Channel.Minkabu:
      case Channel.Okaneko:
      case Channel.RakutenUnyouSoudan:
      case Channel.SbiUnyouSoudan:
      case Channel.VitalitySmart:
      case Channel.Yahoo:
      default:
    }
    return {
      path: '/',
    };
  });

  return { lpLocation };
};
