<script setup lang="ts">
import AppHeaderContentsForPcGuestNav from './AppHeaderContentsForPcGuestNav.vue';
import AppHeaderContentsForPcMemberNav from './AppHeaderContentsForPcMemberNav.vue';
import type { FragmentType } from '@/gql';
import { graphql } from '@/gql';

const props = defineProps<{
  viewer: Maybe<FragmentType<typeof AppHeaderContentsForPcViewerFragment>>;
}>();

const AppHeaderContentsForPcViewerFragment = graphql(/* GraphQL */ `
  fragment AppHeaderContentsForPcViewer on Viewer {
    ...AppHeaderContentsForPCMemberNavViewer
  }
`);

const viewerFragment = useComputedFragment(
  AppHeaderContentsForPcViewerFragment,
  () => props.viewer
);
</script>

<template>
  <AppHeaderContentsForPcMemberNav
    v-if="viewerFragment != null"
    :viewer="viewerFragment"
  />
  <AppHeaderContentsForPcGuestNav v-else />
</template>
