<script setup lang="ts">
import { graphql } from '@/gql';
import type { FragmentType } from '@/gql';
import ServiceLogoLink from 'theme/components/molecules/ServiceLogoLink.vue';

const AppHeaderLogoViewerFragment = graphql(/* GraphQL */ `
  fragment AppHeaderLogoViewer on Viewer {
    currentChannel
  }
`);

const props = defineProps<{
  exclusive: boolean;
  viewer: Maybe<FragmentType<typeof AppHeaderLogoViewerFragment>>;
}>();

const viewerFragment = useComputedFragment(
  AppHeaderLogoViewerFragment,
  () => props.viewer
);

const { isPartnerLockIn } = usePartnerLockIn(
  () => viewerFragment.value?.currentChannel
);

const isShownLogo = computed(() => !isPartnerLockIn.value);
const { lpLocation } = useLpLocation(
  () => viewerFragment.value?.currentChannel
);
</script>

<template>
  <template v-if="isShownLogo">
    <img
      v-if="exclusive"
      v-bind="$attrs"
      src="~/assets/images/logo/logo_okaneko.svg"
      alt="オカネコ"
    />
    <ServiceLogoLink v-else v-bind="$attrs" :location="lpLocation" />
  </template>
</template>
