<script setup lang="ts">
import AppHeaderLogo from './AppHeaderLogo.vue';
import { graphql } from '@/gql';
import type { FragmentType } from '@/gql';
import { useWindowScroll, watchDebounced } from '@vueuse/core';

const AppHeaderContainerViewerFragment = graphql(/* GraphQL */ `
  fragment AppHeaderContainerViewer on Viewer {
    ...AppHeaderLogoViewer
  }
`);

const props = withDefaults(
  defineProps<{
    exclusive?: boolean;
    noDivider?: boolean;
    viewer: Maybe<FragmentType<typeof AppHeaderContainerViewerFragment>>;
  }>(),
  {
    exclusive: false,
    noDivider: false,
  }
);

const viewerFragment = useComputedFragment(
  AppHeaderContainerViewerFragment,
  () => props.viewer
);

const isHeaderHidden = ref(false);
const { y } = useWindowScroll();
watchDebounced(y, (y, oldY) => (isHeaderHidden.value = y > oldY), {
  deep: true,
  debounce: 20,
});

const { isMobile } = useDeviceType();
const height = computed(() => (isMobile.value ? '48px' : '80px'));
</script>

<template>
  <header
    :class="[
      $style['header-wrapper'],
      {
        [$style['header-wrapper--no-divider']]: noDivider,
        [$style['header-wrapper--hidden']]: isHeaderHidden,
      },
    ]"
  >
    <div :class="[$style['header-wrapper__header'], $style.header]">
      <h1 :class="$style.header__heading">
        <slot name="logo">
          <ClientOnly>
            <div :class="$style.header__logoWrapper">
              <AppHeaderLogo
                :exclusive="exclusive"
                :viewer="viewerFragment"
                :class="$style.header__logo"
              />
            </div>
            <template #fallback>
              <noscript>オカネコ</noscript>
            </template>
          </ClientOnly>
        </slot>
      </h1>
      <div :class="$style.header__nav">
        <slot />
      </div>
    </div>
  </header>
</template>

<style lang="scss" module>
$header-height: v-bind('height');

.header-wrapper {
  background-color: $color-surface-primary;
  border-bottom: 1px solid $color-border-low-emphasis;
  position: sticky;
  top: 0;
  z-index: 2;
  transition: transform 0.3s;

  &--hidden {
    box-sizing: border-box;
    transform: translateY(-100%);
  }

  &--no-divider {
    border-bottom: none;
  }

  &__header {
    width: 100%;
    padding: 0 40px;
    height: $header-height;

    @include mobile-only {
      padding: 0 16px 0 40px;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  &__heading {
    display: flex;
    align-self: stretch;
    flex: 0 1 auto;
    margin: 0;
    line-height: 1;

    & > a {
      display: flex;
      align-items: center;
    }
  }

  &__logoWrapper {
    align-self: center;
    line-height: 0;
    height: 25px;
    @include mobile-only {
      height: 18px;
    }
  }

  &__logo {
    width: auto;
    height: 100%;
    color: $color-text-and-object-accent-primary;
  }

  &__nav {
    flex-shrink: 0;
  }
}
</style>
