<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

const params = withDefaults(defineProps<{ location?: RouteLocationRaw }>(), {
  location: () => ({
    path: '/',
  }),
});

const route = useRoute();
const path = (loc: RouteLocationRaw) => {
  if (typeof loc === 'string') return loc;
  return loc.path;
};

const external = computed(
  // insurance 配下から出るか外から insurance 配下に入るとき true になる
  () =>
    route.path.startsWith('/insurance') !==
    path(params.location)?.startsWith('/insurance')
);
</script>

<template>
  <NuxtLink class="link" :to="location" :external="external">
    <img
      src="~/assets/images/logo/logo_okaneko.svg"
      alt="オカネコ"
      class="link__logo"
    />
  </NuxtLink>
</template>

<style lang="scss" scoped>
.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: 1;

  &__logo {
    width: 100%;
    height: 100%;
    color: $color-text-and-object-accent-primary;
  }
}
</style>
