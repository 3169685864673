<script setup lang="ts">
import {
  useHeaderSubNavForMember,
  useHeaderSubNavsForGuest,
} from '../useHeaderNavs';
import type { FragmentType } from '@/gql';
import { graphql } from '@/gql';
import ButtonClose from 'theme/components/atoms/ButtonClose.vue';
import NuxtLinkFlat from 'theme/components/atoms/NuxtLinkFlat.vue';
import UserThumbnail from 'theme/components/atoms/UserThumbnail.vue';

const props = defineProps<{
  viewer?: FragmentType<typeof AppHeaderDrawerViewerFragment>;
}>();
const emit = defineEmits<{
  closeDrawer: [];
  logout: [];
}>();

const AppHeaderDrawerViewerFragment = graphql(/* GraphQL */ `
  fragment AppHeaderDrawerViewer on Viewer {
    ...UseHeaderNavsViewerForMember
    nickname
    latestDiagnosis {
      result {
        personality {
          type
        }
      }
    }
  }
`);
const viewerFragment = useComputedFragment(
  AppHeaderDrawerViewerFragment,
  () => props.viewer
);

const { isAuthenticated } = useAuthenticationState();

const personalityId = computed(
  () => viewerFragment.value?.latestDiagnosis?.result.personality.type
);
const nickname = useUserNicknameForDisplay(viewerFragment.value?.nickname);

computed(() =>
  viewerFragment.value?.nickname
    ? `${viewerFragment.value.nickname}さん`
    : 'ゲストさん'
);
const subNavLinksForMember = useHeaderSubNavForMember(viewerFragment);
const subNavLinksForGuest = useHeaderSubNavsForGuest();
</script>

<template>
  <nav :class="$style.drawer">
    <ButtonClose :class="$style.drawer__close" @click="emit('closeDrawer')" />
    <div :class="[$style.drawer__profile, $style.profile]">
      <UserThumbnail
        :personality-type="personalityId"
        :class="$style.profile__thumbnail"
      />
      <div :class="$style.profile__box">
        <p :class="$style.profile__name">{{ nickname }}</p>
      </div>
    </div>

    <template v-if="isAuthenticated">
      <ul :class="[$style.drawer__links, $style.links]">
        <li
          v-for="({ title, href, condition }, i) in subNavLinksForMember"
          :key="i"
          :class="$style.links__item"
        >
          <NuxtLink v-if="condition" :href="href" :class="$style.link">{{
            title
          }}</NuxtLink>
        </li>
        <li :class="$style.links__item">
          <button :class="$style.link" @click="emit('logout')">
            ログアウト
          </button>
        </li>
      </ul>
    </template>
    <template v-else>
      <ul :class="[$style.drawer__links, $style.links]">
        <li
          v-for="(nav, i) in subNavLinksForGuest"
          :key="i"
          :class="$style.links__item"
        >
          <NuxtLinkFlat
            v-if="nav.variant === 'primary-button' && nav.condition"
            :class="$style.button"
            :to="nav.href"
            variant="primary"
            >{{ nav.title }}</NuxtLinkFlat
          >
          <NuxtLink
            v-if="nav.variant === 'link' && nav.condition"
            :href="nav.href"
            :class="$style.link"
            >{{ nav.title }}</NuxtLink
          >
        </li>
      </ul>
    </template>
  </nav>
</template>

<style lang="scss" module>
.drawer {
  @include create-stacking-context;

  & {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // AppHeaderContentsForSpのpadding-rightに合わせることで
    // ハンバーガーボタンと同じ位置にする
    padding: 32px;
    background-color: $color-surface-primary;
  }

  &__close {
    $pad: 12px;

    box-sizing: initial;
    align-self: flex-end;
    margin: -$pad;
    padding: $pad;
    color: $color-text-and-object-medium-emphasis;
    width: 18px;
    height: 18px;
    z-index: 1;
  }

  &__profile {
    margin-right: 32px;
    width: 100%;
    z-index: 0;

    &:nth-child(n + 2) {
      margin-top: 8px;
    }
  }

  &__links {
    width: 100%;

    &:nth-child(n + 2) {
      margin-top: 32px;
    }
  }
}

.profile {
  display: flex;
  align-items: center;

  &__thumbnail {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
  }

  &__box {
    margin-left: 18px;
  }

  &__name {
    display: block;
    font-size: 16px;
    font-weight: bold;
    word-break: break-all;
  }
}

.links {
  list-style: none;

  &__item {
    line-height: 1;

    &:nth-child(n + 2) {
      margin-top: 24px;
    }
  }
}

.link {
  color: $color-text-and-object-high-emphasis;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
}

.button {
  display: block;
  background: linear-gradient(94deg, #ff5e00, #ffc412);
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  padding: 18px 24px;
  width: 100%;
  text-align: center;
}
</style>
