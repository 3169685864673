<script setup lang="ts">
import IconHamburger from '~theme/components/atoms/icons/IconHamburger.vue';

const emit = defineEmits<{
  click: [MouseEvent];
}>();
</script>

<template>
  <button :class="$style.button" @click="emit('click', $event)">
    <IconHamburger :class="$style.button__icon" />
  </button>
</template>

<style lang="scss" module>
.button {
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    color: $color-text-and-object-medium-emphasis;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}
</style>
