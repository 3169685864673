<script setup lang="ts">
import ButtonHamburger from 'theme/components/atoms/ButtonHamburger.vue';
import ButtonNotification from 'theme/components/atoms/ButtonNotification.vue';

const emit = defineEmits<{
  openNotification: [MouseEvent];
  openDrawer: [MouseEvent];
}>();

const { isAuthenticated } = useAuthenticationState();
const { notifications } = useNotifications();
const notificationCount = computed(() => notifications.value.length);
</script>

<template>
  <ul :class="$style.actions">
    <li v-if="isAuthenticated" :class="[$style.actions__item, $style.action]">
      <!-- TODO: パネルが開いてるときはopen:notificationをopenしないようにする -->
      <ButtonNotification
        :class="$style.action__button"
        :notification-count="notificationCount"
        @click="emit('openNotification', $event)"
      />
    </li>
    <li :class="[$style.actions__item, $style.action]">
      <ButtonHamburger
        :class="$style.action__button"
        @click="emit('openDrawer', $event)"
      />
    </li>
  </ul>
</template>

<style lang="scss" module>
.actions {
  display: flex;
  align-items: stretch;
  list-style: none;

  &__item {
    max-width: 48px;
    flex: 0 0 auto;
  }
}

.action {
  &__button {
    padding: 12px;
    height: 100%;
  }
}
</style>
