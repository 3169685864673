<script setup lang="ts">
import { useHeaderSubNavsForGuest } from '../useHeaderNavs';
import LoginLink from 'theme/components/atoms/LoginLink.vue';

const navs = useHeaderSubNavsForGuest();
</script>

<template>
  <nav :class="$style.nav">
    <ul :class="[$style.nav__links, $style.links]">
      <li v-for="(nav, i) in navs" :key="i" :class="$style.links__item">
        <template v-if="nav.condition">
          <template v-if="nav.variant === 'link'">
            <LoginLink
              v-if="typeof nav.href !== 'string' && nav.href.path === '/login'"
              :class="$style.link"
              >{{ nav.title }}</LoginLink
            >
            <NuxtLink v-else :class="$style.link" :href="nav.href">{{
              nav.title
            }}</NuxtLink>
          </template>
          <NuxtLink
            v-else-if="nav.variant === 'primary-button'"
            :class="$style.button"
            :to="nav.href"
            variant="primary"
            >{{ nav.title }}</NuxtLink
          >
        </template>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" module>
.nav {
  display: flex;
  align-items: center;

  &__links {
    &:nth-child(n + 2) {
      margin-left: 24px;
    }
  }

  &__buttons {
    &:nth-child(n + 2) {
      margin-left: 32px;
    }

    @include tablet-only {
      &:nth-child(n + 2) {
        margin-left: 24px;
      }
    }
  }
}

.links {
  display: flex;
  align-items: center;
  list-style: none;

  &__item {
    flex: 0 0 auto;

    &:nth-child(n + 2) {
      margin-left: 32px;
    }

    @include tablet-only {
      &:nth-child(n + 2) {
        margin-left: 24px;
      }
    }
  }
}

.link {
  color: $color-text-and-object-high-emphasis;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
}

.button {
  display: block;
  background: linear-gradient(94deg, #ff5e00, #ffc412);
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  padding: 18px 24px;
}
</style>
