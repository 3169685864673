import type { Breadcrumbs } from '~/components/molecules/BreadcrumbsStrip/BreadcrumbsStrip.vue';

type OmitParameter<T, OptionalKeys extends string> = Omit<T, OptionalKeys> &
  Partial<T>;

type MatchResult = {
  matched: boolean;
  remain: string | null;
};

type MatchContext = ReturnType<typeof useMatchContext>['value'];

interface BreadcrumbsMatcher {
  (ctx: MatchContext, remainingPath: string): MatchResult;
}

type BreadcrumbsLookupTree = {
  matcher: BreadcrumbsMatcher;
  breadcrumbs:
    | OmitParameter<Breadcrumbs[number], 'location'>
    | ((ctx: MatchContext) => OmitParameter<Breadcrumbs[number], 'location'>);
  children: BreadcrumbsLookupTree[];
};

const withPrefix = (
  prefix: string,
  breadcrumbsItem:
    | OmitParameter<Breadcrumbs[number], 'location'>
    | ((ctx: MatchContext) => OmitParameter<Breadcrumbs[number], 'location'>),
  ...children: BreadcrumbsLookupTree[]
) => ({
  matcher: (_: MatchContext, path: string) => {
    const match = path.match(new RegExp(`^${prefix}(/.*)?$`));
    return {
      matched: match != null,
      remain: match?.[1] ?? null,
    };
  },
  breadcrumbs: breadcrumbsItem,
  children,
});

const breadcrumbsLookupTree: BreadcrumbsLookupTree = {
  matcher: (_: MatchContext, path: string) => ({
    matched: true,
    remain: path.replace(/^\//, ''),
  }),
  breadcrumbs: {
    label: 'オカネコ',
    description: '',
  },
  children: [
    withPrefix('register', {
      label: '登録',
      description: '',
    }),
    withPrefix('login', {
      label: 'ログイン',
      description: '',
    }),
    withPrefix('policies/tos', {
      label: '利用規約',
      description: '',
    }),
    withPrefix('policies/privacy', {
      label: 'プライバシーポリシー',
      description: '',
    }),
    withPrefix('policies/cookiepolicycmp', {
      label: '利用者情報の外部送信について',
      description: '',
    }),
    withPrefix('faq', { label: 'よくあるご質問', description: '' }),
    withPrefix('contact', { label: 'お問い合わせ', description: '' }),
    withPrefix('lp/fiduciaryduty', {
      label: 'フィデューシャリーデューティー宣言',
      description: '',
    }),
    withPrefix('policies/intermediary', {
      label: '金融サービス仲介業に係る明示事項',
      description: '',
    }),
    withPrefix('policies/dispute', {
      label: '当社の苦情処理・紛争解決に係る体制について',
      description: '',
    }),
    withPrefix('policies/entruster', {
      label: '委託元金融機関に関するご案内',
      description: '',
    }),
    withPrefix('policies/solicitation', { label: '勧誘方針', description: '' }),
    withPrefix('policies/antisocial', {
      label: '反社会的勢力に対する基本方針',
      description: '',
    }),
    withPrefix('policies/attention', {
      label: 'ユーザー様向け注意喚起情報',
      description: '',
    }),
    withPrefix('interview/[0-9]+', (ctx) => {
      const interviewId = parseInterviewId(
        ctx.path.match(/^\/interview\/([0-9]+)/)?.[1] ?? ''
      );
      const title =
        interviewId == null
          ? ''
          : ctx.interviews.find((i) => i.id === interviewId)!.title;
      return {
        label: title,
        description: '',
      };
    }),
  ],
};

const toBreadcrumbsItemParts = (
  ctx: MatchContext,
  b:
    | OmitParameter<Breadcrumbs[number], 'location'>
    | ((ctx: MatchContext) => OmitParameter<Breadcrumbs[number], 'location'>)
): OmitParameter<Breadcrumbs[number], 'location'> =>
  typeof b === 'function' ? b(ctx) : b;

const createBreadcrumbs = (
  ctx: MatchContext,
  remainingPath: string | null,
  leaves: BreadcrumbsLookupTree[]
): Breadcrumbs => {
  if (remainingPath == null) return [];
  let matchResult: MatchResult;
  const leaf = leaves.find(
    (l) => (matchResult = l.matcher(ctx, remainingPath)).matched
  );
  if (leaf == null) return [];
  return [
    {
      location: ctx.path.replace(new RegExp(`${matchResult!.remain}$`), ''),
      ...toBreadcrumbsItemParts(ctx, leaf.breadcrumbs),
    },
    ...createBreadcrumbs(ctx, matchResult!.remain, leaf.children),
  ];
};

const useMatchContext = () => {
  const route = useRoute();
  const interviews = useInterviewSummaries(() => ['1', '2', '3', '4'] as const);

  return computed(() => ({
    path: route.path,
    interviews: interviews.value,
  }));
};

export const useBreadcrumbs = () => {
  const ctx = useMatchContext();

  return computed(() =>
    createBreadcrumbs(ctx.value, ctx.value.path, [breadcrumbsLookupTree])
  );
};
