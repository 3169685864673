<script setup lang="ts">
import AppFooter from '@/components/organisms/AppFooter/AppFooter.vue';
import AppHeader from '@/components/organisms/AppHeader/AppHeader.vue';
import AppHeaderDrawer from '@/components/organisms/AppHeader/AppHeaderDrawer/AppHeaderDrawer.vue';
import AppHeaderDrawerNew from '@/components/organisms/AppHeader/AppHeaderDrawer/AppHeaderDrawerNew.vue';
import AppLayoutBase from '@/components/organisms/AppLayoutBase/AppLayoutBase.vue';
import MailActivationAlert from '@/components/organisms/MailActivationAlert/MailActivationAlert.vue';
import { graphql } from '@/gql';
import FadeInTransition from 'theme/components/transitions/FadeInTransition.vue';
import HorizontalCollapseTransition from 'theme/components/transitions/HorizontalCollapseTransition.vue';
import { useDeviceType } from 'theme/composables/useDeviceType';
import BreadcrumbsStrip from '~/components/molecules/BreadcrumbsStrip/BreadcrumbsStrip.vue';
import AppHeaderNotificationForSp from '~/components/organisms/AppHeader/AppHeaderNotificationForSp.vue';

const props = withDefaults(
  defineProps<{
    exclusive?: boolean;
    noHeader?: boolean;
    noFooter?: boolean;
    noDivider?: boolean;
  }>(),
  {
    exclusive: false,
    noHeader: false,
    noFooter: false,
    noDivider: false,
  }
);

const AppHeaderViewerQuery = graphql(/* GraphQL */ `
  query AppHeaderViewer {
    viewer2 {
      ... on ViewerQueryErrorPayload {
        error
      }
      ... on ViewerQuerySuccessPayload {
        result {
          id
          currentChannel
          ...AppHeaderViewer
          ...AppHeaderDrawerViewer
          ...AppHeaderDrawerNewViewer
        }
      }
    }
  }
`);
const { data: result, refresh } = useAuthedQuery(
  AppHeaderViewerQuery,
  {},
  { apollo: { fetchPolicy: 'network-only' } }
);

onMounted(() => {
  const { isAuthenticated } = useAuthenticationState();
  watch(isAuthenticated, () => refresh(), { immediate: true });
});

const viewer = computed(() =>
  result.value?.viewer2.__typename === 'ViewerQuerySuccessPayload'
    ? result.value.viewer2.result
    : undefined
);
const { logout: logout_ } = (() => {
  if (import.meta.server) {
    return { logout: () => {} };
  }
  return useLogout();
})();

const isDrawerOpened = ref(false);
const isNotificationOpened = ref(false);
const route = useRoute();
const router = useRouter();
const exclusive = computed(() => props.exclusive || 'exclusive' in route.query);
const hideFooter = computed(() => props.noFooter || exclusive.value);

router.afterEach(() => {
  closeDrawer();
  closeNotification();
});
const { isDesktop } = useDeviceType();
watch(isDesktop, () => closeDrawer());

const openNotification = () => {
  if (isNotificationOpened.value) {
    return;
  }
  isNotificationOpened.value = true;
};
const closeNotification = () => (isNotificationOpened.value = false);
const openDrawer = () => (isDrawerOpened.value = true);
const closeDrawer = () => (isDrawerOpened.value = false);
const logout = async () => {
  closeDrawer();
  logout_();
};

const { scriptURL } = useReCaptcha();
useHead({
  script: [
    {
      src: scriptURL,
    },
  ],
});

const { isNewDiagnosisResultTarget } = useNewDiagnosisResultTarget(
  () => viewer.value?.currentChannel
);
const shouldShowNewDrawer = computed(() => isNewDiagnosisResultTarget.value);

const breadcrumbs = useBreadcrumbs();
</script>

<template>
  <AppLayoutBase :is-authenticated="!!viewer">
    <div :class="$style.layout">
      <AppHeader
        v-if="!noHeader"
        :class="$style.layout__header"
        :viewer="viewer"
        :exclusive="exclusive"
        :no-divider="noDivider"
        @open-drawer="openDrawer"
        @open-notification="openNotification"
      >
        <template #logo>
          <slot name="logo" />
        </template>
      </AppHeader>
      <ClientOnly>
        <MailActivationAlert />
        <FadeInTransition>
          <AppHeaderNotificationForSp
            v-if="!isDesktop && isNotificationOpened"
            :class="$style.layout__fixedNotification"
            @close-notification="closeNotification"
          />
        </FadeInTransition>
        <Teleport v-if="!isDesktop" to="body">
          <div :class="$style.drawer">
            <FadeInTransition>
              <div
                v-if="isDrawerOpened"
                :class="$style.drawer__background"
                @click="closeDrawer"
              />
            </FadeInTransition>
            <HorizontalCollapseTransition :class="$style.drawer__wrapper">
              <AppHeaderDrawerNew
                v-if="isDrawerOpened && shouldShowNewDrawer && viewer != null"
                :class="$style.drawer__content"
                :viewer="viewer"
                @close-drawer="closeDrawer"
                @logout="logout"
              />
              <AppHeaderDrawer
                v-else-if="isDrawerOpened"
                :class="$style.drawer__content"
                :viewer="viewer"
                @close-drawer="closeDrawer"
                @logout="logout"
              />
            </HorizontalCollapseTransition>
          </div>
        </Teleport>
      </ClientOnly>
      <div v-if="breadcrumbs.length > 1" :class="$style.breadcrumbs">
        <BreadcrumbsStrip :breadcrumbs="breadcrumbs" />
      </div>
      <div :class="[$style.layout__content, $style.content]">
        <slot :class="$style.content__page" />
      </div>
      <AppFooter v-if="!hideFooter" :class="$style.layout__footer" />
    </div>
  </AppLayoutBase>
</template>

<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
}
</style>

<style lang="scss" module>
.breadcrumbs {
  padding: 0 40px;
  border-bottom: 1px solid $color-border-low-emphasis;
  @include mobile-only {
    padding: 0 16px;
  }
}

.layout {
  position: relative;
  min-height: 100vh;
  flex-direction: column;
  display: flex;

  &__header {
    z-index: 1;
  }

  &__fixedNotification {
    position: fixed;
    top: 60px;
    left: 40px;
    right: 40px;
    z-index: 2;
  }

  &__content {
    flex: 1;
  }

  &__footer {
    z-index: 1;
    align-self: stretch;
  }
}

.content {
  display: flex;
  flex-direction: column;

  &__page {
    flex-grow: 1;
  }
}

.drawer {
  &__background {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vh;
    height: 100vh;
    background-color: $color-surface-backdrop;
    z-index: 1;
  }

  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
  }

  &__content {
    width: 280px;
    height: 100vh;
  }
}
</style>
